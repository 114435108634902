import { createRoutine } from 'redux-saga-routines';

const DOCUMENT = 'IA_';
const actions = {
  // TODO Users should be global
  // --- START REALTIME ---
  subscribeCommonSettings: createRoutine(
    DOCUMENT + 'SUBSCRIBE_COMMON_SETTINGS',
  ),
  cancelCommonSettings: createRoutine(DOCUMENT + 'CANCEL_COMMON_SETTINGS'),
  subscribeUsers: createRoutine(DOCUMENT + 'SUBSCRIBE_USERS'),
  cancelUsers: createRoutine(DOCUMENT + 'CANCEL_USERS'),
  subscribeStaff: createRoutine(DOCUMENT + 'SUBSCRIBE_STAFF'),
  cancelStaff: createRoutine(DOCUMENT + 'CANCEL_STAFF'),

  subscribeMandates: createRoutine(DOCUMENT + 'SUBSCRIBE_MANDATES'),
  cancelMandates: createRoutine(DOCUMENT + 'CANCEL_MANDATES'),
  subscribeProperties: createRoutine(DOCUMENT + 'SUBSCRIBE_PROPERTIES'),
  cancelProperties: createRoutine(DOCUMENT + 'CANCEL_PROPERTIES'),
  subscribeFaqs: createRoutine(DOCUMENT + 'SUBSCRIBE_FAQS'),
  cancelFaqs: createRoutine(DOCUMENT + 'CANCEL_FAQS'),
  subscribeMessages: createRoutine(DOCUMENT + 'SUBSCRIBE_MESSAGES'),
  cancelMessages: createRoutine(DOCUMENT + 'CANCEL_MESSAGES'),
  subscribeRepairs: createRoutine(DOCUMENT + 'SUBSCRIBE_REPAIRS'),
  cancelRepairs: createRoutine(DOCUMENT + 'CANCEL_REPAIRS'),
  subscribePinboards: createRoutine(DOCUMENT + 'SUBSCRIBE_PINBOARDS'),
  cancelPinboards: createRoutine(DOCUMENT + 'CANCEL_PINBOARDS'),
  subscribeVotes: createRoutine(DOCUMENT + 'SUBSCRIBE_VOTES'),
  cancelVotes: createRoutine(DOCUMENT + 'CANCEL_VOTES'),
  subscribeVoteItems: createRoutine(DOCUMENT + 'SUBSCRIBE_VOTE_ITEMS'),
  cancelVoteItems: createRoutine(DOCUMENT + 'CANCEL_VOTE_ITEMS'),
  subscribeVoteUsers: createRoutine(DOCUMENT + 'SUBSCRIBE_VOTE_USERS'),
  cancelVoteUsers: createRoutine(DOCUMENT + 'CANCEL_VOTE_USERS'),
  // --- END REALTIME ---
  setFetchTimeLimit: createRoutine(DOCUMENT + 'SET_FETCH_TIME_LIMIT'),
  setFolderMetaAccess: createRoutine(DOCUMENT + 'SET_FOLDER_META_ACCESS'),
  getFolderContent: createRoutine(DOCUMENT + 'GET_FOLDER_CONTENT'),
  clearFolderContent: createRoutine(DOCUMENT + 'CLEAR_FOLDER_CONTENT'),
  getMandateDetails: createRoutine(DOCUMENT + 'GET_MANDATE_DETAILS'),
  getAllMandatePropertiesData: createRoutine(
    DOCUMENT + 'GET_ALL_MANDATE_PROPERTIES_DATA',
  ),
  getMandateProperties: createRoutine(DOCUMENT + 'GET_MANDATE_PROPERTIES'),
  getRootFolders: createRoutine(DOCUMENT + 'GET_ROOT_FOLDERS'),
  searchMandateUsersByLastNameOrEmail: createRoutine(
    DOCUMENT + 'SEARCH_MANDATE_USERS_BY_LASTNAME_OR_EMAIL',
  ),
  getEmailDeliveryDetails: createRoutine(
    DOCUMENT + 'GET_EMAIL_DELIVERY_DETAILS',
  ),
  getPerson: createRoutine(DOCUMENT + 'GET_PERSON'),
  setPersonInvite: createRoutine(DOCUMENT + 'SET_PERSON_INVITE'),
  staffAction: createRoutine(DOCUMENT + 'STAFF_ACTION'),
  mandateAction: createRoutine(DOCUMENT + 'MANDATE_ACTION'),
  messageAction: createRoutine(DOCUMENT + 'MESSAGE_ACTION'),
  repairAction: createRoutine(DOCUMENT + 'REPAIR_ACTION'),
  pinboardAction: createRoutine(DOCUMENT + 'PINBOARD_ACTION'),
  userAction: createRoutine(DOCUMENT + 'USER_ACTION'),
  voteAction: createRoutine(DOCUMENT + 'VOTE_ACTION'),
  voteItemAction: createRoutine(DOCUMENT + 'VOTE_ITEM_ACTION'),
  voteUserAction: createRoutine(DOCUMENT + 'VOTE_USER_ACTION'),
  updateVote: createRoutine(DOCUMENT + 'UPDATE_VOTE'),
  importVoteUsers: createRoutine(DOCUMENT + 'IMPORT_VOTE_USERS'),
  downloadVoteResults: createRoutine(DOCUMENT + 'DOWNLOAD_VOTE_RESULTS'),
  downloadVoteProtocol: createRoutine(DOCUMENT + 'DOWNLOAD_VOTE_PROTOCOL'),
  updateVoteParticipantQuota: createRoutine(
    DOCUMENT + 'UPDATE_VOTE_PARTICIPANT_QUOTA',
  ),
  syncDocuments: createRoutine(DOCUMENT + 'SYNC_DOCUMENTS'),
  getSettings: createRoutine(DOCUMENT + 'GET_SETTINGS'),
  setFilemanagerSettings: createRoutine(DOCUMENT + 'SET_FILEMANAGER_SETTINGS'),
  getFilemanagerSettings: createRoutine(DOCUMENT + 'GET_FILEMANAGER_SETTINGS'),
  sendTestMail: createRoutine(DOCUMENT + 'SEND_TEST_MAIL'),
  exampleMandate: createRoutine(DOCUMENT + 'EXAMPLE_MANDATE'),
  sendExternalPreviewMail: createRoutine(
    DOCUMENT + 'SEND_EXTERNAL_PREVIEW_MAIL',
  ),
  resetExternalPreviewMail: createRoutine(
    DOCUMENT + 'RESET_EXTERNAL_PREVIEW_MAIL',
  ),
  sendHandymanPreviewMail: createRoutine(
    DOCUMENT + 'SEND_HANDYMAN_PREVIEW_MAIL',
  ),
  resetHandymanPreviewMail: createRoutine(
    DOCUMENT + 'RESET_HANDYMAN_PREVIEW_MAIL',
  ),
  getReportReceivers: createRoutine(DOCUMENT + 'GET_REPORT_RECEIVERS'),
  updateRemoteConfigAccentColor: createRoutine(
    DOCUMENT + 'UPDATE_REMOTE_CONFIG_ACCENT_COLOR',
  ),
  resetAllData: createRoutine(DOCUMENT + 'RESET_ALL_DATA'),
  createUsersFromPersons: createRoutine(DOCUMENT + 'CREATE_USERS_FROM_PERSONS'),
  getImmoMoveFiles: createRoutine(DOCUMENT + 'GET_IMMOMOVE_FILES'),
  saveImmoMoveFiles: createRoutine(DOCUMENT + 'SAVE_IMMOMOVE_FILES'),
  getInfocenterFiles: createRoutine(DOCUMENT + 'GET_INFOCENTER_FILES'),
  saveInfocenterFiles: createRoutine(DOCUMENT + 'SAVE_INFOCENTER_FILES'),
  saveInfocenterFAQSections: createRoutine(
    DOCUMENT + 'SAVE_INFOCENTER_FAQ_SECTIONS',
  ),
  updateSettingsField: createRoutine(DOCUMENT + 'UPDATE_SETTINGS_FIELD'),
  getStatistics: createRoutine(DOCUMENT + 'GET_STATISTICS'),
  getLicenseObjects: createRoutine(DOCUMENT + 'GET_LICENSE_OBJECTS'),
  getImmoMoveStatistics: createRoutine(DOCUMENT + 'GET_IMMOMOVE_STATISTICS'),
  logoUpdatedCounter: createRoutine(DOCUMENT + 'LOGO_UPDATED_COUNTER'),
};
export default actions;

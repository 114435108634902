import { Layout, notification } from 'antd';
import siteConfig from 'config/site.config';
import { ID, ScreenLayout } from 'definitions/constants-fe';
import { getSwitcherIconBorderColor } from 'library/functionHelper';
import { throttle } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import WindowResizeListener from 'react-window-size-listener';
import appActions from 'redux/global/app/actions';
import HorizontalMenu from '../HorizontalMenu/HorizontalMenu';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      menuSelect: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.toggleMenuSelect = this.toggleMenuSelect.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage &&
      this.props.errorMessage !== prevProps.errorMessage
    ) {
      this.openNotificationWithIcon('error', this.props.errorMessage);
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.toggleMenuSelect();
    }
  }

  toggleMenuSelect() {
    this.setState((prevState) => {
      return { menuSelect: !prevState.menuSelect };
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate = throttle(() => {
    const newIsDesktop = window.innerWidth >= 768;
    if (this.state.isDesktop !== newIsDesktop) {
      this.setState({ isDesktop: newIsDesktop });
    }
  }, 200);

  openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
    });
  };

  render() {
    const { url } = this.props.match;
    const { rx_height } = this.props;
    const { isDesktop, menuSelect } = this.state;

    const currentUrl = this.props.location.pathname.split('/');
    let heightCount = ScreenLayout.min_heights.mobile;
    if (window.innerWidth >= 768) {
      if (currentUrl.includes(ID.immoapp)) {
        heightCount = ScreenLayout.min_heights.with_topbar;
      } else {
        heightCount = ScreenLayout.min_heights.without_topbar;
      }
    }
    const switcherIconBorderClass = getSwitcherIconBorderColor();
    return (
      <Layout>
        <WindowResizeListener
          onResize={(windowSize) =>
            this.props.toggleAll({
              width: windowSize.windowWidth,
              height: windowSize.windowHeight,
            })
          }
        />
        {/* the history was passed here as in the topbar, we have the logos which should redirect to correct home screen on click. And windows.location sometimes passes inconcistent urls, so to avoid such errors, history props was passed from here */}
        <Topbar switcherIconBorderClass={switcherIconBorderClass} />
        <Layout
          style={{
            flexDirection: 'row',
            overflowX: 'hidden',
          }}
        >
          {isDesktop && <HorizontalMenu url={url} routechanged={menuSelect} />}
          <Layout
            className="isoContentMainLayout"
            style={{ height: rx_height }}
          >
            <Content
              className="isomorphicContent"
              style={{
                flexShrink: '0',
                background: '#f1f3f6',
                position: 'relative',
                minHeight: `calc(100vh - ${heightCount}px)`,
              }}
            >
              <AppRouter />
            </Content>
            <Footer
              style={{
                background: '#ffffff',
                textAlign: 'center',
                borderTop: '1px solid #ededed',
              }}
            >
              {'©'} {moment().year()} {siteConfig.footerText}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.GL_Auth,
    rx_height: state.GL_App.rx_height,
  }),
  { toggleAll },
)(App);

import axios from 'axios';
import {
  CONTROL_FUNCTIONS,
  DB,
  ERROR_CODE,
  ID,
  REGISTER_USER_CODE,
  SECRETS,
  SPACE_FUNCTIONS,
  URLS,
} from 'definitions/constants-fe';
import Firebase from 'library/firebase';
import {
  clearToken,
  clearUserRole,
  getToken,
  setToken,
} from 'library/localStorageHelper';
import { Reporting } from 'library/sentry/reporting';
import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';
import actionsApp from 'redux/global/app/actions';
import actionsImmoApp from 'redux/immoapp/actions';
import actionsImmoFonds from 'redux/immofonds/actions';
import actionsImmoMove from 'redux/immomove/actions';
import { PUBLIC_ROUTE } from 'route.constants';
import actions from './actions';

const {
  database,
  rsfFirestore,
  refFireFunction,
  snapshotAsArray,
  firstAppAuth,
  EmailAuthProvider,
} = Firebase;

export function* loginRequest({ payload }) {
  if (payload) {
    yield setToken(payload);
    yield put(actions.login.success());
  } else {
    yield put(actions.login.failure());
  }
}
function* logout({ payload }) {
  // Cancel realtime listeners
  yield put(actionsImmoApp.cancelCommonSettings());
  yield put(actionsImmoApp.cancelMandates());
  yield put(actionsImmoApp.cancelProperties());
  yield put(actionsImmoApp.cancelMessages());
  yield put(actionsImmoApp.cancelRepairs());
  yield put(actionsImmoApp.cancelPinboards());
  yield put(actionsImmoApp.cancelVotes());
  yield put(actionsImmoApp.cancelVoteItems());
  yield put(actionsImmoApp.cancelVoteUsers());
  yield put(actionsImmoApp.cancelUsers());
  yield put(actionsImmoApp.cancelStaff());
  yield put(actionsImmoFonds.cancelImmovables());
  yield put(actionsImmoMove.cancelTerminationProcesses());
  // Clear Folder Content
  yield put(actionsImmoApp.clearFolderContent());
  // Logout
  clearToken();
  clearUserRole();
  Firebase.logout();
  yield put(actions.logout.success());
  if (payload != null) {
    window.location.href = payload;
  }
}
const authEventsChannel = eventChannel((emit) => {
  const unsubscribe = Firebase.firstAppAuth.onAuthStateChanged((user) => {
    emit({ user });
  });
  // return a function that can be used to unregister listeners when the saga is cancelled
  return unsubscribe;
});
function* checkAuthorization() {
  const token = getToken();
  if (token) {
    yield put(actions.login.success());
    // This trigger logs out the user if no FB Auth exists
    try {
      while (true) {
        const { user } = yield take(authEventsChannel);
        if (!user) {
          // If we logout because no Firebase persistence was set on login, then the token is still active --> We need to redirect immediately to avoid missingPermissions error
          if (getToken()) {
            window.location.href = PUBLIC_ROUTE.LANDING;
          }
          yield put(actions.logout());
        }
      }
    } finally {
      // unregister listener if the saga was cancelled
      if (yield cancelled()) {
        authEventsChannel.close();
      }
    }
  }
}

function* getUser({ payload }) {
  try {
    const docRef = yield call(
      rsfFirestore.getDocument,
      DB.ia_users + '/' + payload.uId,
    );
    if (!docRef.exists) {
      Reporting.Error(new Error(`User with id does not exist: ${payload.uId}`));
      yield put(actions.getUser.failure('global.generalErrorMessage'));
      yield put(actions.logout.trigger());
      return;
    }
    const user = docRef.data();
    user.key = docRef.id;
    if (payload.currentPage === ID.immomove) {
      const snapshot = yield call(
        rsfFirestore.getCollection,
        database
          .collection(DB.im_termination_process)
          .where('tenant.uid', '==', payload.uId),
      );
      const data = snapshotAsArray(snapshot);
      if (data.length > 0) {
        user.tenant = data[0];
      }
    }
    yield put(actions.getUser.success(user));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getUser.failure('global.generalErrorMessage'));
  } finally {
    yield put(actions.getUser.fulfill());
  }
}
function* resendAccessCode({ payload }) {
  try {
    const res = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.im_resend_access_code,
    )({
      email: payload.email,
      zip_code: payload.zip_code,
    });
    // Process was not found
    if (res.data === 301) {
      yield put(actions.resendAccessCode.failure('signintools.wrongInput'));
    } else {
      yield put(
        actions.resendAccessCode.success('process.resendAccessCodeSuccess'),
      );
      yield put(actionsApp.setModal(ID.none));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.resendAccessCode.failure('process.resendAccessCodeError'),
    );
  } finally {
    yield put(actions.resendAccessCode.fulfill());
  }
}
function* sendPwReset({ payload }) {
  try {
    payload.project_id = process.env.REACT_APP_FB_PROJECT_ID;
    const options = {
      method: 'POST',
      url: `${URLS.control_cloud}/${CONTROL_FUNCTIONS.ic_password_reset_mail}?idToken=${SECRETS.access_token}`,
      data: payload,
    };
    yield axios(options);
    yield put(actions.sendPwReset.success('process.resetEmailSuccess'));
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    if (error.response.status === 405) {
      yield put(actions.sendPwReset.failure('process.resetEmailNotFound'));
      return;
    }
    yield put(actions.sendPwReset.failure('process.resetEmailError'));
  } finally {
    yield put(actions.sendPwReset.fulfill());
  }
}
export function* registerUser({ payload }) {
  try {
    payload.invite_key = process.env.REACT_APP_CLIENT_INVITE_CODE;
    const options = {
      method: 'POST',
      url: `${URLS.control_cloud}/${CONTROL_FUNCTIONS.ic_register_user}?idToken=${SECRETS.access_token}`,
      data: payload,
    };
    const result = yield axios(options);
    yield put(actions.registerUser.success(result.data.responseCode));
  } catch (error) {
    // 301: email already exists
    if (error.response && parseInt(error.response.status) === 301) {
      yield put(actions.registerUser.failure(REGISTER_USER_CODE.email_exists));
    } else {
      Reporting.Error(
        new Error(
          `Register user failed with error message: "${error.message}" for user ${payload.email} in space ${process.env.REACT_APP_FB_PROJECT_ID}.`,
        ),
      );
      yield put(actions.registerUser.failure(REGISTER_USER_CODE.error));
    }
  } finally {
    yield put(actions.registerUser.fulfill());
  }
}

export function* updateUserCommunicationSettings({ payload }) {
  // This can be updated over time for other notification settings
  const { subKey, value } = payload;
  try {
    yield database
      .collection(DB.ia_users)
      .doc(firstAppAuth.currentUser.uid)
      .update({ [`communication.${subKey}`]: value });
    yield put(
      actions.updateUserCommunicationSettings.success(
        'account.notificationSettingsUpdated',
      ),
    );
  } catch (error) {
    yield put(
      actions.updateUserCommunicationSettings.failure(
        'global.generalErrorMessage',
      ),
    );
  } finally {
    yield put(actions.updateUserCommunicationSettings.fulfill());
  }
}

export function* updateCurrentUserSecurity({ payload }) {
  const { current_email, current_password, update, email, password } = payload;
  try {
    yield firstAppAuth.currentUser.reauthenticateWithCredential(
      EmailAuthProvider.credential(current_email, current_password),
    );
    switch (update) {
      case ID.email:
        yield database
          .collection(DB.ia_users)
          .doc(firstAppAuth.currentUser.uid)
          .update({ email: email });
        yield put(
          actions.updateCurrentUserSecurity.success('account.emailChanged'),
        );
        break;
      case ID.password:
        yield firstAppAuth.currentUser.updatePassword(password);
        yield put(
          actions.updateCurrentUserSecurity.success('account.passwordChanged'),
        );
        break;
      default:
        break;
    }
    // Close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    switch (error.code) {
      case ERROR_CODE.auth_wrong_password:
        yield put(
          actions.updateCurrentUserSecurity.failure('auth.wrongPassword'),
        );
        break;
      default:
        yield put(
          actions.updateCurrentUserSecurity.failure(
            'global.generalErrorMessage',
          ),
        );
        Reporting.Error(error);
        break;
    }
  } finally {
    yield put(actions.updateCurrentUserSecurity.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.checkAuthorization.TRIGGER, checkAuthorization),
    takeEvery(actions.login.TRIGGER, loginRequest),
    takeEvery(actions.logout.TRIGGER, logout),
    takeEvery(actions.getUser.TRIGGER, getUser),
    takeEvery(actions.resendAccessCode.TRIGGER, resendAccessCode),
    takeEvery(actions.sendPwReset.TRIGGER, sendPwReset),
    takeEvery(actions.registerUser.TRIGGER, registerUser),
    takeEvery(
      actions.updateUserCommunicationSettings.TRIGGER,
      updateUserCommunicationSettings,
    ),
    takeEvery(
      actions.updateCurrentUserSecurity.TRIGGER,
      updateCurrentUserSecurity,
    ),
  ]);
}

import axios from 'axios';
import { DB, ID, SPACE_FUNCTIONS } from 'definitions/constants-fe';
import Firebase from 'library/firebase';
import { showMessage } from 'library/functionHelper';
import { Reporting } from 'library/sentry/reporting';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

const { rsfFirestore } = Firebase;

function* setLanguageId({ payload }) {
  const { uid, languageId } = payload;
  try {
    yield call(rsfFirestore.updateDocument, `${DB.ia_users}/${uid}`, {
      language: languageId,
    });
    yield put(actions.setLanguageId.success());
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', ID.error);
    yield put(actions.setLanguageId.failure());
  } finally {
    yield put(actions.setLanguageId.fulfill());
  }
}

function* getIntroductionPdf({ payload }) {
  try {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${SPACE_FUNCTIONS.ia_pdf_introduction}`,
      data: payload,
      responseType: 'blob',
      timeout: 30000,
    };
    // Contains pdf-title as header
    const response = yield axios(options);
    if (response.data == null || response['headers']?.['pdf-title'] == null) {
      throw new Error(
        `Intro PDF response is not correct: ${payload.user_name}.`,
      );
    }
    yield put(
      actions.getIntroductionPdf.success({
        data: response.data,
        title: response['headers']['pdf-title'],
      }),
    );
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', ID.error);
    yield put(actions.getIntroductionPdf.failure());
  } finally {
    yield put(actions.getIntroductionPdf.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.setLanguageId.TRIGGER, setLanguageId),
    takeEvery(actions.getIntroductionPdf.TRIGGER, getIntroductionPdf),
  ]);
}

import { createRoutine } from 'redux-saga-routines';
const DOCUMENT = 'GL_APP_';
const actions = {
  toggleCollapsed: createRoutine(DOCUMENT + 'COLLPSE_CHANGE'),
  setModal: createRoutine(DOCUMENT + 'SET_MODAL'),
  setLanguageId: createRoutine(DOCUMENT + 'SET_LANGUAGE_ID'),
  setPagination: createRoutine(DOCUMENT + 'SET_PAGINATION'),
  toggleAll: createRoutine(DOCUMENT + 'TOGGLE_ALL'),
  changeCurrent: createRoutine(DOCUMENT + 'CHANGE_CURRENT'),
  setNotification: createRoutine(DOCUMENT + 'SET_NOTIFICATION'),
  getIntroductionPdf: createRoutine(DOCUMENT + 'GET_INTRODUCTION_PDF'),
};

export default actions;

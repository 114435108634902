export const imgToBlob = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      resolve(this.response);
    };
    xhr.onerror = function (error) {
      reject(error);
    };
    xhr.send();
  });
};

export const getFileName = (name, index) => {
  // Not the space was creating the issue, but the round parentheses was creating issue on my end, the name having parentheses, that thumb was not loading on my end.
  const split = name.split('.');
  return `${split[0]}-${new Date().getTime()}-${index}.${split.pop()}`;
};

export const isImageExtention = (extention) => {
  return ['jpg', 'png', 'jpeg'].indexOf(extention) !== -1;
};

import { transition } from 'assets/styles/style-util';
import styled from 'styled-components';
import { palette, size } from 'styled-theme';

const TopbarDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette('custom', 0)};
  margin: -12px -16px;
  width: 360px;
  min-width: 160px;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  ${transition()};

  @media ${size('max_md')} {
    width: 310px;
  }

  .isoDropdownHeader {
    border-bottom: 1px solid ${palette('custom', 2)};
    margin-bottom: 0px;
    padding: 15px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 14px;
      font-weight: 500;
      color: ${palette('text', 0)};
      text-align: center;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .isoDropdownBody {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: ${palette('grayscale', 6)};

    a {
      text-decoration: none;
    }

    .isoDropdownListItem {
      padding: 15px 30px;
      flex-shrink: 0;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      text-align: ${(props) =>
        props['data-rtl'] === 'rtl' ? 'right' : 'left'};
      width: 100%;
      border-bottom: 1px solid ${palette('border', 2)};
      ${transition()};

      &:hover {
        background-color: ${palette('grayscale', 3)};
      }

      .isoListHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
      }

      h5 {
        font-size: 13px;
        font-weight: 500;
        color: ${palette('text', 0)};
        margin-top: 0;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: ${palette('text', 2)};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .isoDate {
        font-size: 11px;
        color: ${palette('grayscale', 1)};
        flex-shrink: 0;
      }
    }
  }

  .isoViewAllBtn {
    font-size: 13px;
    font-weight: 500;
    color: ${palette('text', 2)};
    padding: 10px 15px 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${transition()};

    &:hover {
      color: ${palette('primary', 0)};
    }
  }

  .isoDropdownFooterLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 20px;

    a {
      font-size: 13px;
      font-weight: 500;
      color: ${palette('text', 0)};
      text-decoration: none;
      padding: 10px 20px;
      line-height: 1;
      border: 1px solid ${palette('border', 1)};
      display: flex;
      align-items: center;
      justify-content: center;
      ${transition()};

      &:hover {
        background-color: ${palette('primary', 0)};
        border-color: ${palette('primary', 0)};
        color: ${palette('custom', 0)};
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      color: ${palette('text', 0)};
      line-height: 1.3;
    }
  }

  &.withImg {
    .isoDropdownListItem {
      display: flex;
      flex-direction: row;

      .isoImgWrapper {
        width: 35px;
        height: 35px;
        overflow: hidden;
        margin: ${(props) =>
          props['data-rtl'] === 'rtl' ? '0 0 0 15px' : '0 15px 0 0'};
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: ${palette('grayscale', 9)};
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .isoListContent {
        width: 100%;
        display: flex;
        flex-direction: column;

        .isoListHead {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        h5 {
          margin-bottom: 0;
          padding: ${(props) =>
            props['data-rtl'] === 'rtl' ? '0 0 0 15px' : '0 15px 0 0'};
        }

        .isoDate {
          font-size: 11px;
          color: ${palette('grayscale', 1)};
          flex-shrink: 0;
        }

        p {
          white-space: normal;
          line-height: 1.5;
        }
      }
    }
  }

  &.topbarMail {
    @media ${size('max_sm')} {
      right: -170px;
    }
  }

  &.topbarMessage {
    @media ${size('max_xs')} {
      right: -69px;
    }
  }

  &.topbarNotification {
    @media ${size('max_xs')} {
      right: -120px;
    }
  }

  &.topbarAddtoCart {
    @media ${size('max_xs')} {
      right: -55px;
    }

    .isoDropdownHeader {
      margin-bottom: 0;
    }

    .isoDropdownBody {
      background-color: ${palette('grayscale', 6)};
      display: flex;
      flex-direction: column;

      .isoNoItemMsg {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 30px;
          font-weight: 300;
          color: ${palette('grayscale', 1)};
          line-height: 1.2;
        }
      }
    }
  }

  &.isoUserDropdown {
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    background-color: ${palette('custom', 0)};
    width: 220px;
    min-width: 160px;
    flex-shrink: 0;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    ${transition()};
  }
  &.icon-box {
    width: auto;
    min-width: initial;
    hr {
      border: 0;
      clear: both;
      display: block;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      height: 1px;
    }
    ul {
      padding: 30px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      li {
        display: inline-block;
        width: auto;
        text-align: center;
        margin: 0 20px;
        cursor: pointer;
        svg {
          width: 40px;
          height: 40px;
          transition: all 0.2s linear;
          .st1 {
            fill: #adadad !important;
            transition: all 0.2s linear;
          }
        }
        h4 {
          color: ${palette('text', 3)};
          margin-top: 6px;
          margin-bottom: 0px;
          font-weight: 400;
          text-align: center;
          transition: all 0.2s linear;
        }
        &:hover,
        &.active {
          svg {
            transform: scale(1.1);
            &.icon-app {
              .st1 {
                fill: url('#SVGID_2_') !important;
              }
            }
            &.icon-fonds {
              .st1 {
                fill: url('#SVGIDF_2_') !important;
              }
            }
            &.icon-move {
              .st1 {
                fill: url('#SVGIDM_2_') !important;
              }
            }
          }
          h4 {
            color: ${palette('text', 0)};
          }
        }
      }
    }
    @media ${size('max_sm')} {
      ul {
        padding: 15px;
        li {
          margin: 0 10px;
          h4 {
            font-size: 13px;
            margin-top: 0;
          }
          svg {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }
`;

const UserContent = styled.div`
  ul {
    padding: 10px 0;
    li {
      a {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        @media ${size('max_xs')} {
          padding: 10px 20px;
        }
        .anticon {
          margin-right: 10px;
        }

        color: ${palette('text', 1)};
        &:hover {
          background-color: ${palette('secondary', 6)};
        }
        .user-initials {
          width: 40px;
          height: 40px;
          background-color: ${palette('secondary', 3)};
          border-radius: 50%;
          display: flex;
          margin: auto;
          font-weight: bold;
          align-items: center;
          span {
            margin: auto;
          }
        }
        .user-details {
          padding-left: 15px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
export { TopbarDropdownWrapper, UserContent };

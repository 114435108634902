import { message } from 'antd';
import { intl } from 'components/Utility/IntlMessages';
import { DateFormat, FOLDER_PATTERN, ID } from 'definitions/constants-fe';
import { getUserRole } from 'library/localStorageHelper';
import _ from 'lodash';
import moment from 'moment';

export const isEmptyDeep = (obj) => {
  if (_.isObject(obj)) {
    if (Object.keys(obj).length === 0) {
      return true;
    }
    return _.every(_.map(obj, (v) => isEmptyDeep(v)));
  } else if (_.isString(obj)) {
    return !obj.length;
  }
  return false;
};

export const getMandateCodeOrFirstNumberInString = (str) => {
  const mandateCode = str.match(/#IA[0-9]+#/);
  if (mandateCode != null) {
    return getFirstNumberInString(mandateCode[0]);
  }
  return getFirstNumberInString(str);
};

/**
 * Remove the folder pattern like`#IA-MANDATE-<id>#` from a string.
 *
 * @param {string} identifier any string. Could contain the pattern.
 *
 */
export const removeDocumentFolderPattern = (identifier) => {
  // Define the regular expression pattern to match the type delimiter
  const patternTotal = /#IA-?(MANDATE|PROPERTY|OBJECT|PERSON)?-?[0-9]+#(.*)/;

  // Check if identifier exists
  const matchTotal = identifier.match(patternTotal);
  if (matchTotal == null) {
    return identifier;
  }
  return matchTotal.pop()?.trim() ?? identifier;
};

/**
 * Extracts type ("MANDATE", "PROPERTY" or "OBJECT") and id from an identifier
 * with the form of `#IA-MANDATE-<id>#`, `#IA-PROPERTY-<id>#` or `#IA-OBJECT-<id>#`.
 *
 * @param {string} identifier form of `#IA-XXX-<id>#`
 *
 * @example
 * ```
 * // Returns {type: "MANDATE", id: 1000}
 * extractId("#IA-MANDATE-1000#");
 * ```
 */
export const extractDocumentFolderPattern = (identifier) => {
  // Define the regular expression pattern to match the type delimiter
  const patternTotal = /#IA-?(MANDATE|PROPERTY|OBJECT|PERSON)?-?[0-9]+#/g;
  const patternType = /(MANDATE|PROPERTY|OBJECT|PERSON)/g;
  const patternId = /([0-9]+)/g;

  // Check if identifier exists
  const matchTotal = identifier.match(patternTotal);
  if (matchTotal == null) {
    return null;
  }

  // Find all matches
  const matchType = matchTotal[0].match(patternType) ?? [
    FOLDER_PATTERN.MANDATE,
  ];
  const matchId = matchTotal[0].match(patternId);

  // Note: this should never happen
  if (matchType.length > 1) {
    throw Error('Did find more than one type match for "' + identifier + '"');
  }

  // Note: this should never happen
  if (matchId == null) {
    throw Error('Did not find any id match for "' + identifier + '"');
  } else if (matchId.length > 1) {
    throw Error('Did find more than one id match for "' + identifier + '"');
  }

  return {
    type: matchType[0],
    id: Number(matchId[0]),
  };
};

export const getFirstNumberInString = (str) => {
  return parseInt(str.match(/\d+/)) || -1;
};

export const checkMinUserRole = (minRole) => {
  const userRole = getUserRole();
  return userRole != null && parseInt(userRole) >= minRole;
};

export const appendDatabaseKeyToData = (data = {}, useInnerData = false) => {
  const dataSource = [];
  if (useInnerData) {
    Object.keys(data).map((component) => {
      return dataSource.push({
        ...data[component].data,
        key: data[component].id,
      });
    });
  } else {
    Object.keys(data).map((property) => {
      return dataSource.push({
        ...data[property],
        key: property,
      });
    });
  }
  return dataSource;
};

export const formatToReadableNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export const outputNumber = (input, decimalPart) => {
  if (!input || input === '') {
    if (input !== 0) {
      return '--';
    }
  }
  const number = parseFloat(input).toFixed(decimalPart);
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  return parts.join('.');
};

export const handleTimeStamps = (data, utc0 = false) => {
  let d;
  if (data) {
    d = new Date(
      (data.seconds ? data.seconds : data._seconds ? data._seconds : data) *
        1000,
    );
  } else {
    d = new Date();
  }
  if (utc0) {
    return new Date(d - d.getTimezoneOffset() * 60000);
  }
  return d;
};

export const combineDate = (date, time) => {
  const newDate = moment(moment.unix(date)).format(DateFormat.compare_normal);
  const newTime = moment(moment.unix(time)).format(DateFormat.time);
  const dateTime = moment(
    `${newDate} ${newTime}`,
    DateFormat.display_full,
  ).format();
  return handleTimeStamps(moment(dateTime).unix(), true);
};

export const getFormattedDate = (item, format) => {
  if (item == null) {
    return '';
  } else {
    if (item.seconds == null) {
      return moment(item).format(format);
    } else {
      return moment.unix(item.seconds).format(format);
    }
  }
};

export const commonDateFilter = (data, compareTo, compareWhat) => {
  return data.filter((data) => {
    if (
      compareTo.sign ===
      intl.formatMessage({
        id: 'filter.before',
      })
    ) {
      return moment(moment.unix(data[compareWhat].seconds)).isBefore(
        moment(compareTo.date),
        ID.day,
      );
    } else if (
      compareTo.sign ===
      intl.formatMessage({
        id: 'filter.equal',
      })
    ) {
      return (
        moment
          .unix(data[compareWhat].seconds)
          .format(DateFormat.display_normal) ===
        moment(compareTo.date).format(DateFormat.display_normal)
      );
    } else if (
      compareTo.sign ===
      intl.formatMessage({
        id: 'filter.after',
      })
    ) {
      return moment(moment.unix(data[compareWhat].seconds)).isAfter(
        moment(compareTo.date),
        ID.day,
      );
    }
    return false;
  });
};

export const showMessage = (
  intlString,
  type = ID.success,
  key = '',
  maxTime = 20,
) => {
  return message[type]({
    content: intl.formatMessage({
      id: intlString,
    }),
    duration: type === ID.error ? 6 : type === ID.loading ? maxTime : 4,
    key: key,
  });
};

export const getFileExt = (fileName) => {
  if (fileName.split('.').length > 1) {
    return fileName.split('.').pop();
  }
  return null;
};

export const setFileName = (originalFileData, file) => {
  if (
    originalFileData.fileName.split('.').length > 1 &&
    ['jpeg', 'jpg', 'png'].includes(originalFileData.fileName.split('.').pop())
  ) {
    return originalFileData.fileName;
  } else {
    return `${originalFileData.fileName}.${file.name.split('.').pop()}`;
  }
};

export const getSwitcherIconBorderColor = () => {
  const currentUrl = window.location.href;
  const res = [
    ID.immoapp,
    ID.immofonds,
    ID.immomove,
    ID.account,
    ID.statistics,
    ID.team,
    ID.settings,
  ].find((ele) => currentUrl.includes(ele));
  return res; // Is the ID or null
};

export const genRandomPassword = (length) => {
  let result = '';
  while (result.length < length) {
    const newString = Math.random()
      .toString(36)
      .replace(/[.il01]/g, '');
    result += newString;
  }
  return result.slice(-length);
};

export const getIntlStringMessage = (id) => {
  return `${intl.formatMessage({
    id: id,
  })}`;
};

export const openInNewTab = (url) => {
  const win = window.open(url, '_blank', 'noopener,noreferrer');
  console.info(win, url);
  if (win) {
    win.focus();
  }
};

export const isInternetExplorer = () => {
  return /*@cc_on!@*/ false || !!document.documentMode;
};

// --- Redux helper ---

export const mergeListenerData = (entries) => {
  // Merge
  let merged = entries[ID.public].concat(entries[ID.private]);
  // Remove duplicates
  merged = merged.filter(
    (ele, index, self) => self.findIndex((t) => t.key === ele.key) === index,
  );
  // Sorting needs to happen in frontend because we combine 2 arrays
  merged.sort((a, b) => {
    if (a.date_creation < b.date_creation) {
      return 1;
    }
    if (a.date_creation > b.date_creation) {
      return -1;
    }
    return 0;
  });
  return merged;
};

export const updateFilteredData = (entriesMerged, entriesFiltered) => {
  return entriesMerged.filter(
    (ele) => entriesFiltered.find((e) => e.key === ele.key) != null,
  );
};

export const addFilesTimeCreated = (files) => {
  return files.map((ele) => {
    ele.time_created = handleTimeStamps(ele.time_created);
    return ele;
  });
};

export const getView = (width) => {
  let newView = ID.mobile_view;
  if (width >= 1220) {
    newView = ID.desktop_view;
  } else if (width >= 768) {
    newView = ID.tab_view;
  }
  return newView;
};

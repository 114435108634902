import { ConfigProvider } from 'antd';
import { generateIntl } from 'components/Utility/IntlMessages';
import {
  DEFAULT_LANGUAGE_ID,
  getCurrentLanguage,
} from 'config/language.config';
import themes, { themeConfig } from 'config/theme/theme.config';
import { AppLocale } from 'config/translation';
import moment from 'moment';
import 'moment/locale/de';
import { RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

export default ({ children }: any): any => {
  // Redux state
  // @ts-ignore
  const { rx_userAccount } = useSelector((state) => state.GL_Auth);

  const currentAppLocale =
    // @ts-ignore
    AppLocale[
      getCurrentLanguage(
        rx_userAccount?.language ||
          process.env.REACT_APP_PLATFORM_LANG ||
          DEFAULT_LANGUAGE_ID,
      )!.id
    ];

  const intlValue = generateIntl({
    locale: currentAppLocale.locale,
    messages: currentAppLocale.messages,
  });

  moment.locale(currentAppLocale.id);

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <RawIntlProvider value={intlValue}>
        {/* @ts-ignore */}
        <ThemeProvider theme={themes[themeConfig.theme]}>
          {children}
        </ThemeProvider>
      </RawIntlProvider>
    </ConfigProvider>
  );
};

import antdEn from 'antd/lib/locale-provider/en_US';
import enMessages from '../../../../../shared/i18n/en.json';

const EnLang = {
  messages: {
    ...enMessages,
  },
  antd: antdEn,
  id: 'en',
  locale: 'en-US',
};
export default EnLang;

import antdIt from 'antd/lib/locale-provider/it_IT';
import itMessages from '../../../../../shared/i18n/it.json';

const itLang = {
  messages: {
    ...itMessages,
  },
  antd: antdIt,
  id: 'it',
  locale: 'it-IT',
};
export default itLang;

import { ID } from 'definitions/constants-fe';
import { usePrevious } from 'library/hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import actionsApp from 'redux/global/app/actions';

export default (): any => {
  const mDispatch = useDispatch();
  const { pathname } = useLocation();
  // @ts-ignore
  const rx_modalStatus = useSelector((state) => state.GL_App.rx_modalStatus);

  const resetModalStatus = React.useCallback(() => {
    if ([ID.none, ID.none_reset].every((ele) => rx_modalStatus !== ele)) {
      mDispatch(actionsApp.setModal(ID.none_reset));
    }
  }, [mDispatch, rx_modalStatus]);

  const prevLocation = usePrevious(pathname);
  React.useEffect(() => {
    if (pathname === prevLocation) {
      return;
    }
    if (pathname?.includes(prevLocation) || prevLocation?.includes(pathname)) {
      // User went to or came back from a subpage
    } else {
      // Reset pagination page
      mDispatch(actionsApp.setPagination({ page: undefined, pageSize: 20 }));
    }
    // Scroll to top of page
    window.scrollTo(0, 0);
    // Reset modal state
    resetModalStatus();
  }, [mDispatch, pathname, prevLocation, resetModalStatus]);

  return null;
};

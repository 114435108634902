import antdDE from 'antd/lib/locale-provider/de_DE';
import deMessages from '../../../../../shared/i18n/de.json';

const deLang = {
  messages: {
    ...deMessages,
  },
  antd: antdDE,
  id: 'de',
  locale: 'de-DE',
};
export default deLang;

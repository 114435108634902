import Loader from 'components/Utility/loader';
import { USER_ROLE } from 'definitions/constants-fe';
import { checkMinUserRole } from 'library/functionHelper';
import { getToken, getUserRole } from 'library/localStorageHelper';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PAGE, PUBLIC_ROUTE, TOOL } from 'route.constants';

const getRoutes = () => {
  return [
    {
      path: `/${TOOL.STATISTICS}`,
      component: lazy(() => import('containers/global/Statistics/Statistics')),
      minRole: USER_ROLE.manager.value,
      access: 'true',
    },
    {
      path: `/${TOOL.TEAM}`,
      component: lazy(() => import('containers/global/Team/Team')),
      minRole: USER_ROLE.manager.value,
      access: 'true',
    },
    {
      path: `/${TOOL.SETTINGS}`,
      component: lazy(() => import('containers/global/Settings/Settings')),
      minRole: USER_ROLE.manager.value,
      access: 'true',
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_DASHBOARD}`,
      component: lazy(() => import('containers/immoapp/Dashboard/Dashboard')),
      minRole: USER_ROLE.tenant_normal.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_MANDATES}`,
      component: lazy(() => import('containers/immoapp/Mandates/Mandates')),
      exact: true, // needed so that subpage works
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_MANDATES}/:value`,
      component: lazy(() =>
        import('containers/immoapp/Mandates/MandatesDetails'),
      ),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_MESSAGES}`,
      component: lazy(() => import('containers/immoapp/Messages/Messages')),
      minRole: USER_ROLE.tenant_normal.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_REPAIRS}`,
      component: lazy(() => import('containers/immoapp/Repairs/Repairs')),
      minRole: USER_ROLE.tenant_normal.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_PINBOARD}`,
      component: lazy(() => import('containers/immoapp/Pinboard/Pinboard')),
      minRole: USER_ROLE.tenant_normal.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_DOCUMENTS}`,
      component: lazy(() => import('containers/immoapp/Documents/Documents')),
      minRole: USER_ROLE.tenant_normal.value,
      access:
        process.env.REACT_APP_IA === 'true' &&
        process.env.REACT_APP_IA_DOCUMENTS_ENABLED.includes(getUserRole())
          ? 'true'
          : 'false',
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_VOTES}`,
      component: lazy(() => import('containers/immoapp/Votes/Votes')),
      exact: true, // needed so that subpage works
      minRole: USER_ROLE.owner.value,
      access:
        process.env.REACT_APP_IA === 'true' &&
        getUserRole() !== USER_ROLE.janitor.value.toString()
          ? 'true'
          : 'false',
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_VOTES}/:value`,
      component: lazy(() => import('containers/immoapp/Votes/VoteDetails')),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOAPP}/${PAGE.IA_USERS}`,
      component: lazy(() => import('containers/immoapp/Users/Users')),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IA,
    },
    {
      path: `/${TOOL.IMMOFONDS}/${PAGE.IF_HOME}`,
      component: lazy(() => import('containers/immofonds/Home/Home')),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IF,
    },
    {
      path: `/${TOOL.IMMOFONDS}/${PAGE.IF_IMMOVABLE}/:value`,
      component: lazy(() => import('containers/immofonds/Immovable/Immovable')),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IF,
    },
    {
      path: `/${TOOL.IMMOFONDS}/${PAGE.IF_COMPONENT}/:value`,
      component: lazy(() => import('containers/immofonds/Component/Component')),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IF,
    },
    {
      path: `/${TOOL.IMMOMOVE}/${PAGE.IM_PROCESSES}`,
      component: lazy(() => import('containers/immomove/Home/Home')),
      exact: true, // needed so that subpage works
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IM,
    },
    {
      path: `/${TOOL.IMMOMOVE}/${PAGE.IM_PROCESSES}/:value`,
      component: lazy(() => import('containers/immomove/Process/Process')),
      minRole: USER_ROLE.manager.value,
      access: process.env.REACT_APP_IM,
    },
    {
      path: `/${TOOL.IMMOMOVE}/${PAGE.IM_START}`,
      component: lazy(() => import('containers/immomove/Tenant/Start/Start')),
      minRole: USER_ROLE.tenant_restricted.value,
      access: process.env.REACT_APP_IM,
    },
    {
      path: `/${TOOL.IMMOMOVE}/${PAGE.IM_QUESTIONNAIRE}`,
      component: lazy(() =>
        import('containers/immomove/Tenant/Questionnaire/Questionnaire'),
      ),
      minRole: USER_ROLE.tenant_restricted.value,
      access: process.env.REACT_APP_IM,
    },
    {
      path: `/${TOOL.IMMOMOVE}/${PAGE.IM_END}`,
      component: lazy(() => import('containers/immomove/Tenant/End/End')),
      minRole: USER_ROLE.tenant_restricted.value,
      access: process.env.REACT_APP_IM,
    },
  ];
};

export default function AppRouter() {
  const [mRoutes, mSetRoutes] = React.useState(getRoutes());
  const isAuthenticated = getToken() != null;
  const rx_userAccount = useSelector((state) => state.GL_Auth.rx_userAccount);
  React.useEffect(() => {
    if (
      rx_userAccount?.role != null &&
      rx_userAccount.role.toString() !== getUserRole()
    ) {
      mSetRoutes(getRoutes());
    }
  }, [rx_userAccount]);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {mRoutes.map((ele, index) => (
          <Route exact={ele.exact} key={index} path={ele.path}>
            {isAuthenticated &&
            checkMinUserRole(ele.minRole) &&
            ele.access === 'true' ? (
              <ele.component />
            ) : (
              <Redirect
                to={{
                  pathname: isAuthenticated
                    ? PUBLIC_ROUTE.PAGE_403
                    : PUBLIC_ROUTE.LANDING,
                }}
              />
            )}
          </Route>
        ))}
      </Switch>
    </Suspense>
  );
}

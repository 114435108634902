import { red } from '@ant-design/colors';
import { ID } from 'definitions/constants-fe';
import { createGlobalStyle } from 'styled-components';
import { font, palette, size } from 'styled-theme';

const GlobalStyles = createGlobalStyle`
 
/*-----------------------------------------------*/ 
// style form previous GlobalStyles
/*-----------------------------------------------*/
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /********** Add Your Global CSS Here **********/

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-bottomRight
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-topLeft
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-topRight
    > .ant-popover-content
    > .ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
  }

  /********** Spaces **********/
  .width160 {
    width: 160px !important;
  }

  /********** Badge **********/

  .ant-badge {
    .ant-badge-dot {
      width: 8px;
      height: 8px;
    }
    &.${ID.pendingUserBadge} {
      .ant-badge-dot {
        background-color: ${palette('custom', 23)};
      }
    }
    &.${ID.communicationDisabledBadge} {
      .ant-badge-dot {
        background-color: ${palette('error', 0)};
      }
    }
  }

  /********** Modal **********/
  
  .ant-modal:not(.not-scrollable) {
    top: 0;
    padding: 0;
    margin: 80px auto;
    height: calc(100% - 160px);

    @media ${size('max_lg')} {
      margin: 8px auto !important;
      height: calc(100% - 16px);
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      max-height: 100%;
    }

    .ant-modal-header {
      .ant-modal-title {
        white-space: normal;
        padding-right: 20px;
      }
    }

    .ant-modal-header,
    .ant-modal-footer {
      z-index: 1;
      white-space: nowrap;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
      }
    }
    &.image-viewer {
      .ant-modal-body {
        padding: 12px;
      }
    }
    &.pdf-viewer {
      .ant-modal-body {
        height: 100vh;
        padding: 0px;
        .ant-row {
          height: 100%;
          .viewer-layout-main {
            overflow-x: auto;
          }
        }
      }
    }
    .ant-modal-body {
      overflow-y: auto;
      @media ${size('max_lg')} {
        padding: 20px 15px;
      }
    }
  }

  /********** Card **********/

  .card-container > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
  }

  .card-container
    > .ant-tabs-card
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  
  .card-container
    > .ant-tabs-card
    > .ant-tabs-bar
    .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }

  /********** FLOT JS **********/
  .flotTip {
    padding: 8px 12px;
    background-color: #36404a;
    z-index: 100;
    color: #ffffff;
    font-size: 13px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    opacity: 0.95;
  }
  .legend tr {
    height: 20px;
  }
  .legendLabel {
    padding: 0 2px;
  }
  .legendColorBox {
    padding-left: 8px;
  }

  /******** QUILL EDITOR *******/
  .quill {
    .ql-editor {
      ul, ol {
        padding-left: 0; // Default is 1.5em
      }
    }
    &.min-height-150 {
      .ql-editor {
        min-height: 160px;
      }
    }
  }

  /******** ANTD NOTIFICATION *******/
  .hide-close-button .ant-notification-notice-close {
    display: none;
  }
  /******* ANTD BUGFIXES ********/
  // Disables Antd badge animation
  .ant-scroll-number-only {
    transition: 0s;
  }
  // Disables Antd badge animation
  .ant-badge-zoom-appear {
    animation: none;
  }
  // Formitem parent with error should not affect children with success
  .ant-row.red-validation {
    .ant-form-item-explain-validating {
      color: ${red.primary};
    }
  }
   ant-form-item ant-form-item-has-success
  .ant-select-clear {
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-top: 0px;
  }
  .ant-badge-count {
    font-weight: 500;
    background: #5293d3;
  }
  .ant-message-notice {
    max-width: 600px;
    margin: 0 auto;
  } 
  .ant-notification.ant-notification-topRight {
    width: inherit;
    .ant-notification-notice {
      width: 400px;
    }
    .create-user-notification {
      width: 550px;
      .ant-notification-notice-description {
        font-size: 13px;
        .notice {
          font-style: italic;
        }
        .email-list {
          max-height: 240px;
          overflow-y: auto;

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .ant-select-item.select-all {
    border-bottom: 1px solid ${palette('border', 0)};
  }
  .ant-btn-loading {
    .ant-btn-loading-icon {
      margin-right: 8px;
      .anticon {
        padding: 0;
      }
    }
  }
  .ant-input {
    font-size: 13px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
  }
  .ant-form-item-label {
    label {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .ant-result-subtitle {
    white-space: pre-wrap;
    margin-top: 10px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 12px 16px;
    margin-right: 20px;
  }
  .ant-select-selection-placeholder {
    opacity: 0.6;
  }
  .ant-divider-horizontal {
    border-top: 1px solid ${palette('border', 0)};
    &.small-margin {
      margin: 6px 0;
    }
    &.small-margin-bottom {
      margin: 0 0 12px 0;
    }
    &.extra-small-margin-bottom {
      margin: 0 0 2px 0;
    }
  }

  // ANTD v4 needed changes
  .ant-popover {
    max-width: 400px;
  }
  .ant-col {
    width: 100%;
  }
  .ant-menu {
    text-align: center;
  }

  .anticon {
    vertical-align: -0.15em;
  }
  .ant-btn .anticon,
  .ant-input-search .anticon {
    font-size: 16px;
    vertical-align: -0.2em;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-color: ${palette('border', 0)};
  }
  .ant-picker-time-panel ul {
    padding: 0;
  }
  .big-padding.isoBoxWrapper {
    padding: 40px;
    @media ${size('max_md')} {
      padding: 25px;
    }
  }

  .ant-layout {
    background: ${palette('secondary', 1)};
    display:block;

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      height:100% !important;
      @media ${size('min_md')} and ${size('max_xl')} {
        flex-shrink: 0;
      }

      @media ${size('max_md')} {
        width: 100%;
        flex-shrink: 0;
      }

      .isomorphicContent {
        input::-moz-placeholder {
          font-family: 'Roboto', sans-serif;  
        }
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: ${palette('custom', 0)};
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }
  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media ${size('max_md')} {
      width: 100%;
    }

    @media ${size('min_md')} and ${size('max_xl')} {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-header {
    line-height: inherit;
    height: inherit;
  }

  .ant-layout-footer {
    font-size: 13px;
    padding: 10px 20px 8px 20px;

    a {
      color: inherit;
      text-decoration: underline;
    }
    p {
      margin: 0;
      font-size: 9px;
      opacity: 0.8;
      line-height: 14px;
    }
  }
`;

export default GlobalStyles;

import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import { DesktopMenu } from 'assets/styles/globalStyledComponents';
import { IntlMessages } from 'components/Utility/IntlMessages';
import { getOptions } from 'containers/global/Sidebar/options';
import { ID } from 'definitions/constants-fe';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

class HorizontalMenu extends Component {
  state = { current: '' };

  componentDidMount() {
    const { pathname } = this.props.location;
    let current = '';
    getOptions().forEach((value) => {
      if (pathname.indexOf(value.key) !== -1) {
        current = value.key;
      }
    });
    this.setState({ current });
  }
  componentDidUpdate(prevProps) {
    if (this.props.routechanged !== prevProps.routechanged) {
      const { pathname } = this.props.location;
      let current = '';
      getOptions().forEach((value) => {
        if (pathname.indexOf(value.key) !== -1) {
          current = value.key;
        }
      });
      this.setState({ current });
    }
  }
  render() {
    const { rx_userAccount } = this.props;
    const { current } = this.state;
    const url = this.props.history.location.pathname.split('/');
    return (
      <DesktopMenu>
        {/* Menu component (Header menu) start */}
        {url.includes(ID.immoapp) && (
          <Menu selectedKeys={[current]} mode="horizontal">
            {getOptions().map((ele) => {
              return (
                rx_userAccount.role >= ele.minRole && (
                  <Menu.Item
                    key={ele.key}
                    style={
                      current.includes(ele.key)
                        ? { backgroundColor: 'rgba(0,0,0,0.06)' }
                        : undefined
                    }
                  >
                    <Link to={`/${ele.key}`}>
                      <Icon
                        component={ele.icon}
                        style={{
                          fontSize: 25,
                          marginRight: 0,
                          marginBottom: 4,
                        }}
                      />
                      <div>
                        <IntlMessages id={ele.label} />
                      </div>
                    </Link>
                  </Menu.Item>
                )
              );
            })}
          </Menu>
        )}
        {/* Menu component (Header menu) end */}
      </DesktopMenu>
    );
  }
}

export default withRouter(
  connect((state) => ({
    rx_userAccount: state.GL_Auth.rx_userAccount,
  }))(HorizontalMenu),
);
